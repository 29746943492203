/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        $(window).scroll(function () {
          var sticky = $('.banner'),
            scroll = $(window).scrollTop();

          if (scroll >= 100) {
            sticky.addClass('fixed');
          } else {
            sticky.removeClass('fixed');
          }
        });

        // ChowNow
        $('.chownow-order-online').on('click', function () {
          if (typeof fbq === 'function') {
            fbq('track', 'InitiateCheckout');
          }

          $('.fost-modal').addClass('fost-modal-open');
        });

        $('.fost-close-modal').on('click', function () {
          $('.fost-modal').removeClass('fost-modal-open');
        });



        // Gravity Forms
        $(document).on('gform_confirmation_loaded', function () {
          if (formId === 1) {
            // console.log('fb Contact');
            fbq('track', 'Contact');
          } else {
            // console.log('fb Lead');
            fbq('track', 'Lead');
          }
        });

        // tel, mailto contacts
        $('a[href^="mailto"], a[href^="tel"]').on('click', function (e) {
          e.preventDefault();
          // console.log('fb Contact');
          fbq('track', 'Contact');
          window.location = $(this).attr('href');
        });

        // Locations page
        $('a[href*="/locations"]').on('click', function (e) {
          e.preventDefault();
          // console.log('fb FindLocation');
          fbq('track', 'FindLocation');
          window.location = $(this).attr('href');
        });

        // Newsletter signup
        $('.ctct-custom-form').submit(function (event) {
          // console.log('fb CompleteRegistration');
          fbq('track', 'CompleteRegistration');
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        $(".rslides").responsiveSlides({
          auto: true, // Boolean: Animate automatically, true or false
          speed: 500, // Integer: Speed of the transition, in milliseconds
          timeout: 5000, // Integer: Time between slide transitions, in milliseconds
          pager: false, // Boolean: Show pager, true or false
          nav: true, // Boolean: Show navigation, true or false
          random: false, // Boolean: Randomize the order of the slides, true or false
          pause: true, // Boolean: Pause on hover, true or false
          pauseControls: true, // Boolean: Pause when hovering controls, true or false
          prevText: "", // String: Text for the "previous" button
          nextText: "", // String: Text for the "next" button
          maxwidth: "", // Integer: Max-width of the slideshow, in pixels
          navContainer: "", // Selector: Where controls should be appended to, default is after the 'ul'
          manualControls: "", // Selector: Declare custom pager navigation
          namespace: "rslides", // String: Change the default namespace used
          before: function () {
            // Function: Before callback
            var video = $(this).find('video');

            if (video.length && window.innerWidth > 767) {
              //reset video to start every time
              video[0].currentTime = 0;
            }

            var slideContentWrapper = $(this).find('.slide-content-wrapper');
            slideContentWrapper.hide(0);
            slideContentWrapper.removeClass('aos-init').removeClass('aos-animate');
          },
          after: function () {
            var slideContentWrapper = $(this).find('.slide-content-wrapper');
            slideContentWrapper.show(0);
            AOS.init();
          }
        });


        function activateAutocomplete() {
          var input, autocomplete, place,
            options = {};


          input = document.getElementById("wpsl-search-input");
          autocomplete = new google.maps.places.Autocomplete(input, options);

          autocomplete.addListener("place_changed", function () {
            place = autocomplete.getPlace();

            /*
             * Assign the returned latlng to the autoCompleteLatLng var.
             * This var is used when the users submits the search.
             */
            if (place.geometry) {
              autoCompleteLatLng = place.geometry.location;
            }
          });
        }
        activateAutocomplete();

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS


      }
    },
    // Food page
    'food': {
      init: function () {
        // JavaScript to be fired on the page

      },
      finalize: function () {
        // JavaScript to be fired on the page, after the init JS
        $('.food-item-wrapper').on('click', function () {
          $('.food-desc-wrapper').removeClass('show-overlay');
          $(this).next().addClass('show-overlay');
          $('body').addClass('open');
        });
        $('.close').on('click', function () {
          $('.food-desc-wrapper').removeClass('show-overlay');
          $('body').removeClass('open');
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
